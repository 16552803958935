<template>
  <section>
    <div v-if="!isLoaded" class="h-screen vld-parent">
      <loading :active="!isLoaded" :is-full-page="false" />
    </div>
    <!-- Email Settings -->

    <div class="mx-auto mt-3 lg:mt-10 md:mt-5  max-w-6xl" v-if="isLoaded">
      <div>
        <div>
          <div class="font-bold text-22px text-oCharcoal">
            <i class="fas fa-envelope text-22px text-oPurple"></i>
            <span class="pl-3">{{
              $t(
                'components.settingsManagement.email.headline.emailSettings.title'
              )
            }}</span
            ><span v-if="isUsedOtorideDefaultEmailCredential" class="pl-1"
              >(Default)</span
            >
          </div>
          <p class="break-words title-description mt-1">
            {{
              $t(
                'components.settingsManagement.email.headline.emailSettings.description'
              )
            }}
          </p>
        </div>
      </div>

      <div :key="`email_${counter}`" class=" mt-3 pt-5 border-t-2">
        <template v-for="(item, itemIndex) in emailSettings">
          <template v-if="itemIndex === 0">
            <SmartAppInput
              :key="itemIndex"
              :rowId="`email_${itemIndex}`"
              :type="item.type"
              :label="item.label"
              :placeholder="item.placeholder"
              :description="item.description"
              :isEdit="item.isEdit"
              :options="item.options"
              v-model="email[`${item.variable}`]"
              @save="onSave(`${item.variable}`, $event)"
            />
          </template>
          <template
            v-if="itemIndex > 0 && !isUsedOtorideDefaultEmailCredential"
          >
            <SmartAppInput
              v-if="itemIndex === 1"
              :key="itemIndex"
              :rowId="`email_${itemIndex}`"
              :type="item.type"
              :label="item.label"
              :placeholder="item.placeholder"
              :description="item.description"
              :isEdit="item.isEdit"
              :options="item.options"
              v-model="email[`${item.variable}`]"
              @save="onSave(`${item.variable}`, $event)"
            />
            <SmartAppInput
              v-if="itemIndex > 1 && itemIndex <= 6 && email.email_is_smtp"
              :key="itemIndex"
              :rowId="`email_${itemIndex}`"
              :type="item.type"
              :label="item.label"
              :placeholder="item.placeholder"
              :description="item.description"
              :isEdit="item.isEdit"
              :options="item.options"
              v-model="email[`${item.variable}`]"
              @save="onSave(`${item.variable}`, $event)"
            />
            <SmartAppInput
              v-if="itemIndex === 7 && !email.email_is_smtp"
              :key="itemIndex"
              :rowId="`email_${itemIndex}`"
              :type="item.type"
              :label="item.label"
              :placeholder="item.placeholder"
              :description="item.description"
              :isEdit="item.isEdit"
              :options="item.options"
              v-model="email[`${item.variable}`]"
              @save="onSave(`${item.variable}`, $event)"
            />
          </template>
          <template
            v-if="
              itemIndex === 8 &&
                item.variable === 'should_send_trip_invoice_email'
            "
          >
            <SmartToggleInput
              :key="itemIndex"
              :label="item.label"
              :description="item.description"
              :onText="item.onText"
              :offText="item.offText"
              :onValue="item.onValue"
              :offValue="item.offValue"
              v-model="email[`${item.variable}`]"
              @save="onSave(`${item.variable}`, $event)"
            />
          </template>
        </template>
      </div>
    </div>
  </section>
</template>
<script>
import SmartAppInput from '@/components/form/SmartAppInput.vue'
import SmartToggleInput from '@/components/form/SmartToggleInput.vue'
import { useEndpoints } from '@/composables'
import { EventBus } from '@/utils'
export default {
  name: 'EmailSettings',
  components: { SmartAppInput, SmartToggleInput },
  data() {
    return {
      isLoaded: false,
      counter: 0,
      email: {
        email_address: null,
        email_is_smtp: null,
        email_username: null,
        email_password: null,
        email_host: null,
        email_port: null,
        email_encryption: null,
        email_api_key: null,
        should_send_trip_invoice_email: null,
      },
      emailSettings: [
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.email.emailSettings.senderEmailAddress.title'
          ),
          placeholder: `e.g  noreply@otoride.co`,
          description: this.$t(
            'components.settingsManagement.email.emailSettings.senderEmailAddress.description'
          ),
          variable: 'email_address',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: this.$t(
            'components.settingsManagement.email.emailSettings.emailCredentialProvider.title'
          ),
          placeholder: this.$t(
            'components.settingsManagement.email.emailSettings.emailCredentialProvider.placeholder'
          ),
          options: [
            { text: 'SMTP', value: true },
            { text: 'SendGrid', value: false },
          ],
          description: this.$t(
            'components.settingsManagement.email.emailSettings.emailCredentialProvider.description'
          ),
          variable: 'email_is_smtp',
          isEdit: false,
        },

        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.email.emailSettings.emailUsername.title'
          ),
          placeholder: `e.g user101`,
          description: this.$t(
            'components.settingsManagement.email.emailSettings.emailUsername.description'
          ),
          variable: 'email_username',
          isEdit: false,
        },
        {
          type: 'password',
          label: this.$t(
            'components.settingsManagement.email.emailSettings.emailPassword.title'
          ),
          placeholder: `e.g  1234qwe`,
          description: this.$t(
            'components.settingsManagement.email.emailSettings.emailPassword.description'
          ),
          variable: 'email_password',
          isEdit: false,
        },
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.email.emailSettings.emailHost.title'
          ),
          placeholder: `e.g ssa.as2.sd`,
          description: this.$t(
            'components.settingsManagement.email.emailSettings.emailHost.description'
          ),
          variable: 'email_host',
          isEdit: false,
        },
        {
          type: 'number',
          label: this.$t(
            'components.settingsManagement.email.emailSettings.emailPort.title'
          ),
          placeholder: `e.g 2026`,
          description: this.$t(
            'components.settingsManagement.email.emailSettings.emailPort.description'
          ),
          variable: 'email_port',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: this.$t(
            'components.settingsManagement.email.emailSettings.emailEncryption.title'
          ),
          description: this.$t(
            'components.settingsManagement.email.emailSettings.emailEncryption.description'
          ),
          options: [
            { value: 'TLS', text: 'TLS' },
            { value: 'SSL', text: 'SSL' },
            { value: 'None', text: 'None' },
          ],
          variable: 'email_encryption',
          isEdit: false,
        },
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.email.emailSettings.emailAPIKey.title'
          ),
          placeholder: `e.g: SG.SiY-..............-piwuRPs`,
          description: this.$t(
            'components.settingsManagement.email.emailSettings.emailAPIKey.description'
          ),
          variable: 'email_api_key',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.email.emailSettings.sendInvoiceEmailAfterRide.title'
          ),
          description: this.$t(
            'components.settingsManagement.email.emailSettings.sendInvoiceEmailAfterRide.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'should_send_trip_invoice_email',
          isEdit: false,
        },
      ],
    }
  },
  computed: {
    orgId() {
      return this.$store.getters['auth/organizationInfo'].id || null
    },
    isUsedOtorideDefaultEmailCredential() {
      return (
        this.$store.getters['auth/organizationInfo'].subscription
          .should_use_otoride_email_credentials || false
      )
    },
    endpoint() {
      return useEndpoints.settings.email.index(this.orgId)
    },
  },
  async created() {
    this.$http
      .get(this.endpoint)
      .then(({ data }) => {
        const formatted = {}
        for (const k in data) {
          formatted[k] = data[k] === null ? '' : data[k]
        }
        this.email = { ...formatted }
        this.isLoaded = true
      })
      .catch((err) => {
        console.log('emailSettingsErr', err)
      })
  },
  mounted() {
    EventBus.$on('open-edit', (id) => {
      const idPrefix = id.split('_')[0]
      if (idPrefix === 'email') {
        this.emailSettings = this.emailSettings.map((item, itemIndex) => {
          if (id === `email_${itemIndex}`) {
            return {
              ...item,
              isEdit: true,
            }
          } else {
            return {
              ...item,
              isEdit: false,
            }
          }
        })
      }
    })
  },
  methods: {
    async onSave(key, val) {
      let toastTitle, toastText

      if (key === 'email_address') {
        toastTitle = `Email Address`
        toastText = `Email Address is updated`
      }
      if (key === 'email_is_smtp') {
        toastTitle = `Email Is SMTP`
        toastText = `Email Is SMTP is updated`
      }
      if (key === 'email_password') {
        toastTitle = `Email Password`
        toastText = `Email Password is updated`
      }
      if (key === 'email_username') {
        toastTitle = `Email Username`
        toastText = `Email Username is updated`
      }
      if (key === 'email_host') {
        toastTitle = `Email Host`
        toastText = `Email Host is updated`
      }
      if (key === 'email_port') {
        toastTitle = `Email Port`
        toastText = `Email Port is updated`
      }
      if (key === 'email_encryption') {
        toastTitle = `Email Encryption`
        toastText = `Email Encryption is updated`
      }
      if (key === 'email_api_key') {
        toastTitle = `SendGrid API Key`
        toastText = `SendGrid API Key is updated`
      }
      if (key === 'should_send_trip_invoice_email') {
        toastTitle = `Success`
        toastText = `Status is updated`
      }

      let data = new FormData()
      data.append(key, val)

      await this.$http
        .patch(this.endpoint, data)
        .then((res) => {
          console.log(res)
          this.emailSettings = this.emailSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: toastTitle,
              text: toastText,
            },
            3000
          )
          this.counter++
        })
        .catch((err) => {
          console.log('onSaveError', err)
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: toastTitle,
              text: 'Failed to update settings',
            },
            3000
          )
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.pencil-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.title-description {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #63636c !important;
}
</style>
